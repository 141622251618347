import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

// Import components from material UI
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentTitle: {
      marginTop: 10,
      color: '#FFFFFF'
    },
    contentText: {
      color: '#FFFFFF',
      marginTop: theme.spacing(3)
    }
  })
)

const OmCPR: React.FC = () => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Typography variant="h6" className={classes.contentTitle}>
        OM CPR NUMRE
      </Typography>
      <Typography className={classes.contentText} align="left">
        <p>
          CPR-nummer kaldes også for personnummer, og det består af personens
          fødselsdato plus fire kontrolcifre. De sidste fire kontrolcifre bliver
          udregnet ved hjælp af “Modulo 11”, og de er dermed ikke tilfældige. De
          fleste it-systemer har indbygget kontroller baseret på Modulo 11 for
          at opdage om der bruges ugyldige CPR-numre.{' '}
        </p>
        <p>
          Modulo 11 beregninger er forholdsvis komplekse, så derfor er denne
          side blevet oprettet for at gøre det nemt kan få dannet CPR-numre, som
          kan bruges til testning af software.{' '}
        </p>
        <p>
          De udregnede CPR-numre er tilfældige, men de opfylder kravene for
          CPR-numre. Det er ikke muligt at vide, om det beregnede CPR-nummer er
          tilknyttet en person eller om numret er ubrugt. Vi anbefaler derfor,
          at man kun bruger CPR-numrene fra denne side i afgrænsede testmiljøer.
        </p>
      </Typography>
    </React.Fragment>
  )
}

export default OmCPR
