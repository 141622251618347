import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import ContentPage from 'components/ContentPage'

import { ThemeProvider } from '@material-ui/core/styles'
import { defaultTheme as themeInstance } from 'themes/default'
import 'stylesheets/App.css'

const App: React.FC = () => {
  return (
    <Router>
      <ThemeProvider theme={themeInstance}>
        <ContentPage />
      </ThemeProvider>
    </Router>
  )
}

export default App
