import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

// Router components
import { Link, Route, Switch, useLocation } from 'react-router-dom'

// Material UI components
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import IconButton from '@material-ui/core/IconButton'
import MoreIcon from '@material-ui/icons/MoreVert'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

// Custom components
import ContentBox from 'components/ContentBox'
import FiktivCPR from 'components/FiktivCPR/FiktivCPR'
import OmCPR from 'components/FiktivCPR/OmCPR'
import KontaktCPR from 'components/FiktivCPR/KontaktCPR'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1
    },
    default_tab: {
      color: '#707070',
      backgroundColor: '#FFFFFF',
      fontSize: 15,
      fontFamily: ['"Montserrat"', 'Open Sans'].join(','),
      fontWeight: 600
    },
    active_tab: {
      color: '#FF8E2B',
      backgroundColor: '#FFFFFF',
      fontSize: 15,
      fontFamily: ['"Montserrat"', 'Open Sans'].join(','),
      fontWeight: 600
    },
    contentTitle: {
      marginTop: 40,
      color: '#FFFFFF'
    },
    contentText: {
      color: '#FFFFFF',
      marginTop: theme.spacing(3)
    },
    title_desktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    title_mobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    menu_desktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex'
      }
    },
    menu_mobile: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    }
  })
)

const ContentPage: React.FC = () => {
  // Get the styling
  const classes = useStyles()

  const location = useLocation()

  // Local state for more button on mobile
  const [moreShownEl, setMoreShownEl] = React.useState<null | HTMLElement>(null)

  // Open mobile more button
  const handleMobileOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMoreShownEl(event.currentTarget)
  }

  // Close mobile more button
  const handleMobileClose = () => {
    setMoreShownEl(null)
  }

  // Mobile menu
  const renderMobileMenu = (
    <React.Fragment>
      <IconButton
        className={classes.menu_mobile}
        aria-label="show more"
        aria-haspopup="true"
        onClick={handleMobileOpen}
        color="inherit">
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={moreShownEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(moreShownEl)}
        onClose={handleMobileClose}>
        <MenuItem onClick={handleMobileClose} component={Link} to="/">
          <p>Home</p>
        </MenuItem>
        <MenuItem onClick={handleMobileClose} component={Link} to="/about">
          <p>Om CPR-numre</p>
        </MenuItem>
        <MenuItem onClick={handleMobileClose} component={Link} to="/contact">
          <p>Kontakt</p>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )

  // Desktop menu
  const renderDesktopMenu = (
    <Tabs value={location.pathname} className={classes.menu_desktop}>
      <Tab value="/" label="Hjem" component={Link} to="/" />
      <Tab value="/about" label="Om CPR-Numre" component={Link} to="/about" />
      <Tab value="/contact" label="Kontakt" component={Link} to="/contact" />
    </Tabs>
  )

  return (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            className={clsx(classes.title, classes.title_desktop)}>
            CPR numre til software testing
          </Typography>
          <Typography
            variant="h6"
            className={clsx(classes.title, classes.title_mobile)}>
            FiktivCPR
          </Typography>
          {renderDesktopMenu}
          {renderMobileMenu}
        </Toolbar>
      </AppBar>
      <Switch>
        <Route exact path="/" component={ContentBox(FiktivCPR)} />
        <Route path="/about" component={ContentBox(OmCPR)} />
        <Route path="/contact" component={ContentBox(KontaktCPR)} />
      </Switch>
    </React.Fragment>
  )
}

export default ContentPage
