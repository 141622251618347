export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Random = 'RANDOM'
}

export interface CPR {
  d1: number
  d2: number
  d3: number
  d4: number
  d5: number
  d6: number
  d7: number
  d8: number
  d9: number
  d10: number
}

export const randomDate = () => {
  let from = new Date(1900, 1, 1).getTime()
  let to = new Date().getTime()
  return new Date(from + Math.random() * (to - from))
}

export const sample = (array: any[]) => {
  return array[~~(Math.random() * array.length)]
}

export const calculateCPR = (date: Date, gender: Gender): CPR => {
  // Pick out the full year
  let full_year = date.getFullYear()

  // Get day, month, and year
  let day = String(date.getDate()).padStart(2, '0')
  let month = String(date.getMonth() + 1).padStart(2, '0')
  let year = String(full_year).slice(2, 4)

  // Convert to digits
  let d1 = parseInt(day[0])
  let d2 = parseInt(day[1])
  let d3 = parseInt(month[0])
  let d4 = parseInt(month[1])
  let d5 = parseInt(year[0])
  let d6 = parseInt(year[1])

  // If random gender, select gender
  if (gender === Gender.Random) {
    gender = Math.random() > 0.5 ? Gender.Male : Gender.Female
  }

  // Determine the 7th digit, see table in:
  // https://da.wikipedia.org/wiki/CPR-nummer
  let intYear = parseInt(year)
  let options: number[] = []
  switch (true) {
    case intYear < 37:
      if (full_year >= 1900 && full_year <= 1999) {
        options.push(0, 1, 2, 3)
      }
      if (full_year >= 2000 && full_year <= 2036) {
        options.push(4)
      }
      if (full_year >= 2000 && full_year <= 2057) {
        options.push(5, 6, 7, 8)
      }
      if (full_year >= 2000 && full_year <= 2036) {
        options.push(9)
      }
      break
    case intYear < 58:
      if (full_year >= 1900 && full_year <= 1999) {
        options.push(0, 1, 2, 3)
      }
      if (full_year >= 1937 && full_year <= 1999) {
        options.push(4, 9)
      }
      if (full_year >= 2000 && full_year <= 2057) {
        options.push(5, 6, 7, 8)
      }
      break
    default:
      if (full_year >= 1900 && full_year <= 1999) {
        options.push(0, 1, 2, 3)
      }
      if (full_year >= 1937 && full_year <= 1999) {
        options.push(4, 9)
      }
      if (full_year >= 1858 && full_year <= 1899) {
        options.push(5, 6, 7, 8)
      }
      break
  }
  options = Array.from(new Set(options))
  let d7 = sample(options)

  // Digit 8, 9, 10
  let d8, d9, d10, modulus
  while (true) {
    // First get random numbers for digit 8 & 9
    d8 = sample([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
    d9 = sample([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])

    // Then get all valid d10 options
    modulus =
      (4 * d1 +
        3 * d2 +
        2 * d3 +
        7 * d4 +
        6 * d5 +
        5 * d6 +
        4 * d7 +
        3 * d8 +
        2 * d9) %
      11
    d10 = modulus === 0 ? 0 : 11 - modulus
    if (d10 === 1) {
      continue
    }

    // Depending on male or female, subset the possible options
    if (
      (gender === Gender.Male && [1, 3, 5, 7, 9].includes(d10)) ||
      (gender === Gender.Female && [0, 2, 4, 6, 8].includes(d10))
    ) {
      break
    }
  }

  return {
    d1: d1,
    d2: d2,
    d3: d3,
    d4: d4,
    d5: d5,
    d6: d6,
    d7: d7,
    d8: d8,
    d9: d9,
    d10: d10
  }
}
