import { FieldProps } from 'formik'
import React from 'react'
import { FormControlLabel, Radio } from '@material-ui/core'

export const RadioFormField: React.FC<FieldProps> = ({
  field,
  form,
  ...props
}) => {
  return (
    <React.Fragment>
      <FormControlLabel
        control={<Radio {...field} {...props} id={field.name} />}
        label="Placeholder"
        {...props}
      />
    </React.Fragment>
  )
}
