import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

// Import components from material UI
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Box from '@material-ui/core/Box'
import { Alert, AlertTitle } from '@material-ui/lab'

// Custom form fields
import * as yup from 'yup'
import { setLocale } from 'yup'
import { Formik, Form, Field, FormikHelpers } from 'formik'
import { TextFormField } from 'components/FormFields/TextFormField'
import { RadioFormField } from 'components/FormFields/RadioFormField'

// Importing utiilty functions related to CPR calculations
import { CPR, Gender, calculateCPR } from 'components/FiktivCPR/util'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    button: {
      margin: theme.spacing(1)
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    contentTitle: {
      marginTop: 10,
      color: '#FFFFFF'
    },
    contentText: {
      color: '#FFFFFF',
      marginTop: theme.spacing(3)
    },
    labelText: {
      marginLeft: '5px',
      color: '#FFFFFF',
      fontWeight: 600
    },
    labelLine: {
      borderBottom: 1,
      color: '#707070'
    },
    inputField: {
      color: 'white',
      '& input:valid + fieldset': {
        borderColor: 'white',
        borderWidth: 2
      },
      'label + &': {
        color: 'white'
      }
    },
    submitBtn: {
      borderRadius: '20px',
      margin: theme.spacing(1)
    },
    resultField: {
      margin: 0,
      backgroundColor: '#FFFFFF'
    },
    grid: {
      marginTop: theme.spacing(1)
    }
  })
)

const FiktivCPR: React.FC = () => {
  // Get the styling
  const classes = useStyles()

  // State
  const [cpr, setCPR] = useState<CPR | undefined>(undefined)
  const [gender, setGender] = useState<Gender>(Gender.Random)

  // Login inputs
  interface FormInputs {
    years: string
    months: string
    gender: Gender
  }

  // Input handlers
  const genderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender((event.target as HTMLInputElement).value as Gender)
  }
  const handleSubmit = async (
    values: FormInputs,
    { setErrors, setSubmitting }: FormikHelpers<FormInputs>
  ) => {
    try {
      if (values.years !== '' && values.months !== '') {
        const current = new Date()
        const birthday = new Date(
          current.getFullYear(),
          current.getMonth() -
            (parseInt(values.years) * 12 + parseInt(values.months)),
          current.getDate()
        )
        setCPR(calculateCPR(birthday, values.gender))
      }
    } catch (error) {
      console.log(error)
      return
    } finally {
      console.log('finally')
    }
  }

  let stringCpr: string | undefined = cpr
    ? [
        cpr.d1,
        cpr.d2,
        cpr.d3,
        cpr.d4,
        cpr.d5,
        cpr.d6,
        cpr.d7,
        cpr.d8,
        cpr.d9,
        cpr.d10
      ].join('')
    : undefined

  // Set locale to danish
  setLocale({
    number: {
      min: ({ min }: { min: string }) => `Skal være minimum ${min}`,
      max: ({ max }: { max: string }) => `Må maksimum være ${max}`
    },
    mixed: {
      required: 'Skal udfyldes',
      notType: 'Ugyldig'
    }
  })

  // Frontend validation schema
  const schema = yup.object({
    years: yup.number().required().min(0).max(120),
    months: yup.number().required().min(0).max(11)
  })

  // Set initial form values
  const initialValues: FormInputs = {
    years: '',
    months: '',
    gender: Gender.Random
  }

  return (
    <React.Fragment>
      <Typography variant="h6" className={classes.contentTitle}>
        FIKTIV PERSON
      </Typography>
      <Formik
        className={classes.form}
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={initialValues}>
        <Form>
          <Grid container className={classes.root} spacing={3} justify="center">
            <Grid
              container
              item
              xs={12}
              justify="center"
              className={classes.grid}>
              <Grid item xs={10} style={{ textAlign: 'left' }}>
                <Box borderBottom={1} color="#707070">
                  <Typography className={classes.labelText}>ALDER</Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              justify="center"
              className={classes.grid}>
              <Grid item xs={5} style={{ paddingRight: '10px' }}>
                <Field
                  label="År"
                  name="years"
                  component={TextFormField}
                  id="years"
                />
              </Grid>
              <Grid item xs={5} style={{ paddingLeft: '10px' }}>
                <Field
                  label="Måneder"
                  name="months"
                  component={TextFormField}
                  id="months"
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              justify="center"
              className={classes.grid}>
              <Grid item xs={10} style={{ textAlign: 'left' }}>
                <Box borderBottom={1} color="#707070">
                  <Typography className={classes.labelText}>KØN</Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={10}>
              <RadioGroup
                row
                name="gender"
                value={gender}
                onChange={genderChange}
                style={{ width: 'auto' }}>
                <Field
                  name="male"
                  label="Mand"
                  value={Gender.Male}
                  style={{ paddingRight: '13px' }}
                  component={RadioFormField}
                />
                <Field
                  name="female"
                  label="Kvinde"
                  value={Gender.Female}
                  style={{ paddingRight: '13px' }}
                  component={RadioFormField}
                />
                <Field
                  name="random"
                  label="Tilfældig"
                  value={Gender.Random}
                  component={RadioFormField}
                />
              </RadioGroup>
            </Grid>

            <Grid
              container
              item
              xs={12}
              justify="center"
              className={classes.grid}>
              {stringCpr && (
                <Grid item xs={10}>
                  <Alert severity="info" icon={false}>
                    <AlertTitle>Fiktivt CPR</AlertTitle>
                    {stringCpr}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={10}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="secondary"
                  className={classes.submitBtn}
                  endIcon={<ArrowForwardIosIcon />}>
                  Udregn
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </React.Fragment>
  )
}

export default FiktivCPR
