import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

// Import components from material UI
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentTitle: {
      marginTop: 10,
      color: '#FFFFFF'
    },
    contentText: {
      color: '#FFFFFF',
      marginTop: theme.spacing(3)
    }
  })
)

const ContactCPR: React.FC = () => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Typography variant="h6" className={classes.contentTitle}>
        KONTAKT
      </Typography>
      <Typography className={classes.contentText}>
        Spørgsmål og andre henvendelser kan sendes til: <br />
        <br />
        fiktivcpr@gmail.Com
      </Typography>
    </React.Fragment>
  )
}

export default ContactCPR
