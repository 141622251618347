import { createMuiTheme } from '@material-ui/core/styles'

export const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: ['"Montserrat"', 'Open Sans'].join(','),
    h6: {
      color: '#0D2137',
      fontWeight: 800
    }
  },
  palette: {
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#FF8E2B'
    }
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        color: '#FFFFFF'
      }
    },
    MuiRadio: {
      colorSecondary: {
        color: '#FFFFFF'
      }
    },
    MuiTextField: {
      root: {
        '& input:valid + fieldset': {
          borderColor: 'white',
          borderWidth: 2
        }
      }
    },
    // Modifying Outlined input is crazy:
    // See: https://stackoverflow.com/questions/53764626/how-to-change-outline-color-of-material-ui-react-input-component
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: '#FFFFFF'
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#FFFFFF',
          '@media (hover: none)': {
            borderColor: '#FFFFFF'
          }
        },
        '&$focused $notchedOutline': {
          borderColor: '#FFFFFF',
          borderWidth: 1
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: 'white'
      }
    },
    MuiInputBase: {
      input: {
        color: 'white'
      }
    }
  }
})
