import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

// Import components from material UI
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 10,
      marginTop: 20,
      paddingBottom: 30,
      background: '#0D2137',
      borderRadius: '20px'
    }
  })
)

const ContentBox = (Page: any) => {
  const classes = useStyles()
  return (props: any) => (
    <div className="page">
      <Container maxWidth="sm">
        <Paper className={classes.root}>
          <Grid container className={classes.root}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Page {...props}></Page>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  )
}

export default ContentBox
