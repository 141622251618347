import { FieldProps, getIn } from 'formik'
import React from 'react'
import TextField from '@material-ui/core/TextField'

export const TextFormField: React.FC<FieldProps> = ({
  field,
  form,
  ...props
}) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <TextField
      fullWidth
      variant="outlined"
      helperText={errorText}
      error={!!errorText}
      {...field}
      {...props}
    />
  )
}
